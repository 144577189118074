function getS() {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16)
    .substring(1);
}
function getGuid() {
  return `${getS()}${getS()}-${getS()}-${getS()}-${getS()}-${getS()}${getS()}${getS()}`;
}
function getGuid8() {
  return `${getS()}${getS()}`;
}
export function guid() {
  return getGuid()
}

export function guid8() {
  return getGuid8()
}