import axios from 'axios';

import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { DicomMetadataStore, MODULE_TYPES } from '@ohif/core';

import Dropzone from 'react-dropzone';
import filesToStudies from './filesToStudies';

import { extensionManager } from '../../App.tsx';

import { Icon, Button, LoadingIndicatorProgress } from '@ohif/ui';

import md5 from 'js-md5';
import { guid } from './guid'



const getLoadButton = (onDrop, text, isDir) => {
  return (
    <Dropzone
      onDrop={onDrop}
      noDrag
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <Button
            rounded="full"
            variant="contained" // outlined
            disabled={false}
            endIcon={<Icon name="launch-arrow" />} // launch-arrow | launch-info
            className={classnames('font-medium', 'ml-2')}
            onClick={() => { }}
          >
            {text}
            {isDir ? (
              <input
                {...getInputProps()}
                webkitdirectory="true"
                mozdirectory="true"
              />
            ) : (
              <input {...getInputProps()} />
            )}
          </Button>
        </div>
      )}
    </Dropzone>
  );
};

type LocalProps = {
  modePath: string;
};

function ReadFile({ modePath }: LocalProps) {
  const navigate = useNavigate();
  const dropzoneRef = useRef();
  const [dropInitiated, setDropInitiated] = React.useState(true);

  // Initializing the dicom local dataSource
  const dataSourceModules = extensionManager.modules[MODULE_TYPES.DATA_SOURCE];
  const localDataSources = dataSourceModules.reduce((acc, curr) => {
    const mods = [];
    curr.module.forEach(mod => {
      console.log(mod);
      if (mod.type === 'localApi') {
        mods.push(mod);
      }
    });
    return acc.concat(mods);
  }, []);
  const firstLocalDataSource = localDataSources[0];
  const dataSource = firstLocalDataSource.createDataSource({});

  const microscopyExtensionLoaded = extensionManager.registeredExtensionIds.includes(
    '@ohif/extension-dicom-microscopy'
  );



  const onDrop = async acceptedFiles => {
    const studies = await filesToStudies(acceptedFiles, dataSource);
    console.log(acceptedFiles);
    console.log(dataSource);
    console.log(studies);
    const query = new URLSearchParams();
    console.log(query);
    console.log(microscopyExtensionLoaded);
    if (microscopyExtensionLoaded) {
      // TODO: for microscopy, we are forcing microscopy mode, which is not ideal.
      //     we should make the local drag and drop navigate to the worklist and
      //     there user can select microscopy mode
      const smStudies = studies.filter(id => {
        const study = DicomMetadataStore.getStudy(id);
        return (
          study.series.findIndex(s => s.Modality === 'SM' || s.instances[0].Modality === 'SM') >= 0
        );
      });

      if (smStudies.length > 0) {
        smStudies.forEach(id => query.append('StudyInstanceUIDs', id));

        modePath = 'microscopy';
      }
    }

    // Todo: navigate to work list and let user select a mode
    studies.forEach(id => query.append('StudyInstanceUIDs', id));
    console.log(studies);
    query.append('datasources', 'dicomlocal');
    console.log(studies[studies.length - 1]);

    // navigate(`/${modePath}?${decodeURIComponent(query.toString())}`);
    navigate(`/viewer/dicomlocal?StudyInstanceUIDs=${studies[studies.length - 1]}`);

  };


  // 签名配置
  const getHeaderConfig = () => {
    let timestamp = new Date().getTime();
    let nonce = guid()
    var data = {
      'x-bw-timestamp': timestamp,
      'x-bw-nonces': nonce,
      'x-bw-signature': md5(`y7bUVUsTZ4WufEnF${timestamp}${nonce}`),
    }
    return data
  }

  // 从url路径获取
  // const { filePath } = useParams() //|| 'https://uatapp.uh.org.mo/report/knee/knee-00000/'

  const result = new URLSearchParams(window.location.search);
  const filePath = result.get('filePath');
  async function getFiles(fileName) {
    try {
      const response = await axios.get(filePath + fileName, {
        responseType: 'blob',
        headers: {
          ...getHeaderConfig()
        }
      });
      console.log('单个文件>>>>>>', response.data);
      const transToFile = async (blob, fileName, fileType) => {
        return new File([blob], fileName, { type: fileType })
      }
      const fileData = await transToFile(response.data, fileName, '')
      console.log(fileData);
      return fileData
      // onDrop([fileData])


    } catch (error) {
      console.log(error);
    }
  }
  // getFiles()

  // 获取文件列表
  const getFileList = async () => {
    try {
      // https://uatapp.uh.org.mo/file/getFileNames
      const response = await axios.get('/file/getFileNames', {
        params: {
          urlPath: filePath
        },
        headers: {
          ...getHeaderConfig()
        }
      })
      if (!response.data.data) return
      // 列表中所以文件名称
      const fileList = response.data.data
      const filePromise = fileList.map(fileName => {
        return getFiles(fileName)
      })
      // setDropInitiated(false)
      // 获取所有文件
      Promise.allSettled(filePromise).then(res => {
        const file = res.map(item => item.value)
        onDrop(file)
      })


    } catch (error) {

    }
  }

  getFileList()

  // Set body style
  useEffect(() => {
    document.body.classList.add('bg-black');
    return () => {
      document.body.classList.remove('bg-black');
    };
  }, []);

  return (
    <Dropzone
      ref={dropzoneRef}
      onDrop={acceptedFiles => {
        setDropInitiated(true);
        onDrop(acceptedFiles);
      }}
      noClick
    >
      {({ getRootProps }) => (
        <div
          {...getRootProps()}
          style={{ width: '100%', height: '100%' }}
        >
          <div className="flex h-screen w-screen items-center justify-center ">

            <div className="flex flex-col items-center justify-center pt-48">
              <LoadingIndicatorProgress className={'h-full w-full bg-black'} />
            </div>

            {/* <div className="bg-secondary-dark mx-auto space-y-2 rounded-lg py-8 px-8 drop-shadow-md">
              <img
                className="mx-auto block h-14"
                src="./ohif-logo.svg"
                alt="OHIF"
              />
              <div className="space-y-2 pt-4 text-center">
                {dropInitiated ? (
                  <div className="flex flex-col items-center justify-center pt-48">
                    <LoadingIndicatorProgress className={'h-full w-full bg-black'} />
                  </div>
                ) : (
                  <div className="space-y-2">
                    <p className="text-base text-blue-300">
                      Note: You data is not uploaded to any server, it will stay in your local
                      browser application
                    </p>
                    <p className="text-xg text-primary-active pt-6 font-semibold">
                      Drag and Drop DICOM files here to load them in the Viewer
                    </p>
                    <p className="text-lg text-blue-300">Or click to </p>
                  </div>
                )}
              </div>
              <div className="flex justify-around pt-4 ">
                {getLoadButton(onDrop, 'Load files', false)}
                {getLoadButton(onDrop, 'Load folders', true)}
              </div>
            </div> */}
          </div>
        </div>
      )}
    </Dropzone>
  );
}

export default ReadFile;
